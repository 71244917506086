<template>
  <div class="product-review-container">
    <div class="pc">
      <div class="h6">후기</div>
      <div class="rate-container margin-top-24">
        <div class="body2 sub2">후기 {{ allCount }}</div>
        <div class="flex-align" style="gap: 16px; margin-top: 16px">
          <div class="flex-align" style="gap: 12px">
            <star-rating
              :rating="productRate"
              :readOnly="true"
              active-color="#FF6600"
              :star-size="36"
              :increment="0.5"
              :padding="2"
              :star-points="[
                20, 34, 30, 40, 31, 40, 32, 40, 33, 38, 33, 37, 31, 25, 39, 18, 40, 16, 40, 15, 39, 14, 38, 13, 27, 12,
                22, 1, 21, 0, 20, 0, 19, 0, 18, 1, 13, 12, 2, 13, 1, 14, 0, 15, 0, 16, 1, 18, 9, 25, 7, 37, 7, 38, 8,
                40, 9, 40, 10, 40, 20, 34,
              ]"
              :show-rating="false"></star-rating>
            <div class="h4 main">
              {{ productRate }}
            </div>
          </div>
        </div>
      </div>
      <div class="review-tabs">
        <div
          v-for="(tab, idx) in tabs"
          :key="'review-tab-' + idx"
          class="review-tab unselect"
          :class="{
            active: activeTab === tab.value,
            'body4-bold main': activeTab === tab.value,
            'body4-medium sub3': activeTab !== tab.value,
          }"
          @click="setActiveTab(tab.value)">
          <div>{{ tab.name }}</div>
          <div class="count">{{ tab.count }}</div>
        </div>
      </div>
      <template v-if="reviews && reviews.length > 0">
        <!-- <div class="lp-divider-sub5 margin-top-16 margin-bottom-16"></div> -->
        <div v-for="(review, idx) in reviews" :key="'theme-review-' + idx" class="item-review">
          <div style="flex: 1">
            <div class="flex-column" style="gap: 2px">
              <div class="flex-align" style="gap: 4px">
                <div class="body6 sub3">{{ datesFormat(review.created_time, 'date_3') }}</div>
                <div class="line-bar"></div>
                <div class="body6 sub3">{{ authorFormat(review.author) }}</div>
              </div>
              <div class="flex-align" style="gap: 4px">
                <star-rating
                  :rating="review.rate"
                  :readOnly="true"
                  active-color="#FF6600"
                  :star-size="16"
                  :increment="0.5"
                  :padding="1"
                  :star-points="[
                    20, 34, 30, 40, 31, 40, 32, 40, 33, 38, 33, 37, 31, 25, 39, 18, 40, 16, 40, 15, 39, 14, 38, 13, 27,
                    12, 22, 1, 21, 0, 20, 0, 19, 0, 18, 1, 13, 12, 2, 13, 1, 14, 0, 15, 0, 16, 1, 18, 9, 25, 7, 37, 7,
                    38, 8, 40, 9, 40, 10, 40, 20, 34,
                  ]"
                  :show-rating="false"></star-rating>
                <div class="body5-medium main">
                  {{ review.rate }}
                </div>
              </div>
            </div>
            <div class="review-type-label body7" :class="review.review_type === 2 ? 'prod' : 'cs'">
              {{ review.review_type === 2 ? '제작후기' : '상담후기' }}
            </div>
            <div v-if="review.imgs.length > 0" class="flex-align drag-scroll margin-top-12">
              <div
                v-for="(img, idx) in review.imgs"
                :key="'img-' + idx"
                style="display: inline-block; margin-right: 4px"
                class="unselect"
                @click="clickImg(review.imgs, idx)">
                <e-image
                  :isBg="true"
                  :isLazy="false"
                  :img="img"
                  :useThumb="true"
                  :width="74"
                  :useRatio="true"
                  :imgRadius="8"
                  :imageRates="{ ratio: '1:1' }" />
              </div>
            </div>
            <!-- <div v-if="review.title && review.title !== ''" class="body6 sub3" style="margin:12px 0">
              {{ review.title }}
            </div> -->
            <div class="body4 main" style="white-space: pre-line">{{ review.content }}</div>
          </div>
        </div>
        <!-- 페이지네이션 -->
        <lp-paginate :filtered.sync="filtered" style="margin-top: 88px" @click="getData"></lp-paginate>
      </template>
      <div v-else class="empty-view">{{ emptyMessage }}</div>
    </div>

    <div class="mobile" style="padding: 0 16px">
      <div class="h6">후기</div>
      <div class="rate-container margin-top-24">
        <div class="body2 sub2">후기 {{ allCount }}</div>
        <div class="flex-align" style="gap: 16px; margin-top: 16px">
          <div class="flex-align" style="gap: 12px">
            <star-rating
              :rating="productRate"
              :readOnly="true"
              active-color="#FF6600"
              :star-size="36"
              :increment="0.5"
              :padding="2"
              :star-points="[
                20, 34, 30, 40, 31, 40, 32, 40, 33, 38, 33, 37, 31, 25, 39, 18, 40, 16, 40, 15, 39, 14, 38, 13, 27, 12,
                22, 1, 21, 0, 20, 0, 19, 0, 18, 1, 13, 12, 2, 13, 1, 14, 0, 15, 0, 16, 1, 18, 9, 25, 7, 37, 7, 38, 8,
                40, 9, 40, 10, 40, 20, 34,
              ]"
              :show-rating="false"></star-rating>
            <div class="h4 main">
              {{ productRate }}
            </div>
          </div>
        </div>
      </div>
      <div class="review-tabs">
        <div
          v-for="(tab, idx) in tabs"
          :key="'review-tab-mobile-' + idx"
          class="review-tab unselect"
          :class="{
            active: activeTab === tab.value,
            'body4-bold main': activeTab === tab.value,
            'body4-medium sub3': activeTab !== tab.value,
          }"
          @click="setActiveTab(tab.value)">
          <div>{{ tab.name }}</div>
          <div class="count">{{ tab.count }}</div>
        </div>
      </div>
      <template v-if="reviews && reviews.length > 0">
        <div v-for="(review, idx) in reviews" :key="'theme-review-' + idx" class="item-review">
          <div class="flex-column" style="gap: 2px">
            <div class="flex-align" style="gap: 4px">
              <div class="body6 sub3">{{ datesFormat(review.created_time, 'date_3') }}</div>
              <div class="line-bar"></div>
              <div class="body6 sub3">{{ authorFormat(review.author) }}</div>
            </div>
            <div class="flex-align" style="gap: 4px">
              <star-rating
                :rating="review.rate"
                :readOnly="true"
                active-color="#FF6600"
                :star-size="16"
                :increment="0.5"
                :padding="1"
                :star-points="[
                  20, 34, 30, 40, 31, 40, 32, 40, 33, 38, 33, 37, 31, 25, 39, 18, 40, 16, 40, 15, 39, 14, 38, 13, 27,
                  12, 22, 1, 21, 0, 20, 0, 19, 0, 18, 1, 13, 12, 2, 13, 1, 14, 0, 15, 0, 16, 1, 18, 9, 25, 7, 37, 7, 38,
                  8, 40, 9, 40, 10, 40, 20, 34,
                ]"
                :show-rating="false"></star-rating>
              <div class="body5-medium main">
                {{ review.rate }}
              </div>
            </div>
          </div>
          <div class="review-type-label body7" :class="review.review_type === 2 ? 'prod' : 'cs'">
            {{ review.review_type === 2 ? '제작후기' : '상담후기' }}
          </div>
          <div v-if="review.imgs.length > 0" class="flex-align drag-scroll margin-top-12">
            <div
              v-for="(img, idx) in review.imgs"
              :key="'img-mobile-' + idx"
              style="display: inline-block; margin-right: 4px"
              @click="clickImg(review.imgs, idx)">
              <e-image
                :isBg="true"
                :isLazy="false"
                :img="img"
                :useThumb="true"
                :width="88"
                :useRatio="true"
                :imgRadius="8"
                :imageRates="{ ratio: '1:1' }" />
            </div>
          </div>
          <!-- <div v-if="review.title !== ''" class="body5 sub3 margin-bottom-12">{{ review.title }}</div> -->
          <div class="body4 main" style="white-space: pre-line">{{ review.content }}</div>
        </div>
        <!-- 페이지네이션 -->
        <lp-paginate :filtered.sync="filtered" :unit="5" class="padding-top-32" @getData="getData"></lp-paginate>
      </template>
      <div v-else class="empty-view">{{ emptyMessage }}</div>
    </div>
    <image-modal ref="imgModal" :imgs="selectedImgs" :idx="selectedImgIdx"></image-modal>
  </div>
</template>
<script>
import LpPaginate from '../component/LpPaginate';
import ImageModal from '../component/ImageModal';
import StarRating from 'vue-star-rating';
import EImage from '../module/EImage.vue';

export default {
  name: 'ProductReview',
  props: {
    productRate: {
      type: Number,
      default: 0,
    },
    isTemp: {
      type: Boolean,
      default: false,
    },
  },
  components: { ImageModal, LpPaginate, StarRating, EImage },
  created() {
    if (this.$route.query.id) {
      this.getData();
    }
    if (this.isTemp) {
      this.tabs.splice(1, 0, { name: '제작후기', value: 2, count: 0 }, { name: '상담후기', value: 1, count: 0 });
    }
  },
  data() {
    return {
      formData: [],
      reviews: [],
      filtered: {
        cur_page: 1,
        page_num: 1,
        total_page: 1,
        page_length: 9,
      },
      // 전체 카운트 수
      allCount: 0,
      selectedImgs: [],
      selectedImgIdx: 0,
      tabs: [
        { name: '전체', value: 0, count: 0 },
        { name: '포토', value: 3, count: 0 },
      ],
      activeTab: 0,
      csReview: [],
      photoReview: [],
      prodReview: [],
    };
  },
  computed: {
    computedReviews() {
      let reviews = this.reviews;
      if (this.activeTab === 1) {
        reviews = this.csReview;
      } else if (this.activeTab === 2) {
        reviews = this.prodReview;
      } else if (this.activeTab === 3) {
        reviews = this.photoReview;
      }
      return reviews;
    },
    emptyMessage() {
      switch (this.activeTab) {
        case 0:
          return '아직 등록된 후기가 없습니다.';
        case 1:
          return '아직 등록된 제작 후기가 없습니다.';
        case 2:
          return '아직 등록된 상담 후기가 없습니다.';
        case 3:
          return '아직 등록된 포토 후기가 없습니다.';
        default:
          return '아직 등록된 후기가 없습니다.';
      }
    },
  },

  methods: {
    authorFormat(author) {
      if (author.length > 2) {
        return author[0] + '*'.repeat(author.length - 2) + author[author.length - 1];
      } else if (author.length === 2) {
        return author[0] + '*';
      } else {
        return author;
      }
    },
    setActiveTab(tab) {
      this.activeTab = tab;
      if (tab === 1) {
        this.filtered.page_num = 1;
        this.filtered.review_type = 1;
        if (this.filtered.has_images) {
          delete this.filtered.has_images;
        }
      } else if (tab === 2) {
        this.filtered.page_num = 1;
        this.filtered.review_type = 2;
        if (this.filtered.has_images) {
          delete this.filtered.has_images;
        }
      } else if (tab === 3) {
        this.filtered.page_num = 1;
        this.filtered.has_images = true;
        if (this.filtered.review_type) {
          delete this.filtered.review_type;
        }
      } else {
        this.filtered.page_num = 1;
        delete this.filtered.review_type;
        delete this.filtered.has_images;
      }

      this.getData();
    },
    clickImg(review, idx) {
      this.selectedImgs = review;
      this.selectedImgIdx = idx;
      this.$nextTick(() => {
        this.$refs.imgModal.open();
      });
    },
    getData() {
      let url = `public/product/${this.$route.query.id}/review`;
      if (this.$route.path.includes('partner')) {
        url = `public/partner/${this.$route.query.id}/product_review`;
      }
      this.$axios
        .get(url, {
          params: this.filtered,
        })
        .then((res) => {
          if (res.status === 200) {
            if (this.activeTab === 1) {
              this.reviews = res.data.data;
            } else if (this.activeTab === 2) {
              this.reviews = res.data.data;
            } else if (this.activeTab === 3) {
              this.reviews = res.data.data;
            } else {
              this.reviews = res.data.data;
              this.allCount = res.data.count;
              if (this.isTemp) {
                this.tabs[0].count = this.allCount;
                this.tabs[1].count = this.reviews.filter((i) => i.review_type === 2).length;
                this.tabs[2].count = this.reviews.filter((i) => i.review_type === 1).length;
                this.tabs[3].count = this.reviews.filter((i) => i.imgs.length > 0).length;
              } else {
                this.tabs[0].count = this.allCount;
                this.tabs[1].count = this.reviews.filter((i) => i.imgs.length > 0).length;
              }
            }

            this.filtered.page_length = res.data.page_length;
            this.filtered.cur_page = res.data.cur_page;
            this.filtered.total_page = res.data.total_page;
            this.$emit('updateReviewCount', this.allCount);
          }
        });
    },
  },
};
</script>
<style lang="stylus" scoped>
@import '~assets/css/lp_main'
.product-review-container
  padding-bottom 40px
.lp-divider-gray3
  border-bottom 12px solid $gray3

.review-tabs
  display flex
  align-items center
  margin 24px 0
  .review-tab
    width 100%
    display flex
    justify-content center
    align-items center
    padding 16px 0
    gap 2px
    border-bottom 1px solid $gray1
    &.active
      padding-bottom 15px
      border-bottom 2px solid $main

.pc
  .rate-container
    display flex
    flex-direction column
    justify-content center
    align-items center
    gap 12px
    padding 24px 0
    border-radius 8px
    background-color $gray4
  .item-review
    display flex
    gap 0 40px
    padding 16px 0
    border-bottom 1px solid $gray2
    white-space pre-wrap

  .img-product
    object-fit cover
    object-position center
    width 100px
    height 100px
    border-radius 8px

.empty-view
  padding 80px 0 !important

.review-type-label
  display flex
  align-items center
  justify-content center
  padding 1px 6px
  width fit-content
  border-radius 4px
  margin 12px 0

.cs
  border 1px solid #0063F7
  color #0063F7

.prod
  border 1px solid #FF6600
  color #FF6600


.line-bar
  width 1px
  height 12px
  background-color $gray1

.mobile
  .rate-container
    display flex
    flex-direction column
    justify-content center
    align-items center
    gap 12px
    padding 24px 0
    border-radius 8px
    background-color $gray4
  .item-review
    padding 20px 0
    border-bottom 1px solid $gray2
    white-space pre-wrap
  .img-product
    object-fit cover
    object-position center
    width 88px
    height 88px
    border-radius 8px
    margin-right 8px
</style>

<template>
   <div class="partner-detail" v-if="partner">
     <div class="partner-info-wrapper" v-if="isLogin && user.partner && user.partner.id === partner.id">
      <div class="partner-info">
        <div>
          <div class="h7 main">{{ partner.nickname }}</div>
          <div class="flex" style="gap: 4px;margin-top: 4px">
            <star-rating
                    :rating="partner.rate"
                    :readOnly="true"
                    active-color="#ff6600"
                    :star-size="12"
                    :increment="0.5"
                    :padding="1"
                    :star-points="[20, 34, 30, 40, 31, 40, 32, 40, 33, 38, 33, 37, 31, 25, 39, 18, 40, 16, 40, 15, 39, 14, 38, 13, 27, 12, 22, 1, 21, 0, 20, 0, 19, 0, 18, 1, 13, 12, 2, 13, 1, 14, 0, 15, 0, 16, 1, 18, 9, 25, 7, 37, 7, 38, 8, 40, 9, 40, 10, 40, 20, 34]"
                    :show-rating="false"
                  ></star-rating>
            <span class="body5-medium main">{{  partner.rate  }} ({{ partner.review_count }})</span>
          </div>
        </div>
        <router-link to="/partner_center/partner_info">
          <button class="button is-primary button-update body2-bold">정보 수정</button>
        </router-link>
      </div>
     </div>

     <div class="sticky-header">
      <div class="sticky-items">
        <div class="sticky-item unselect subtitle5" 
            :class="{'selected': selectedTab === 0}" @click="scrollToSection('partner-info', 0)">파트너 소개</div>
        <div class="sticky-item unselect subtitle5" 
            :class="{'selected': selectedTab === 1}" @click="scrollToSection('partner-template', 1)">템플릿</div>
        <div class="sticky-item unselect subtitle5" 
            :class="{'selected': selectedTab === 2}" @click="scrollToSection('partner-review', 2)">후기 <span v-if="partner.review_count > 0" style="margin-left:4px">{{ partner.review_count }}</span></div>
        <div class="sticky-item unselect subtitle5" 
            :class="{'selected': selectedTab === 3}" @click="scrollToSection('partner-inquiry', 3)">문의 <span v-if="partner.inquiry_count > 0" style="margin-left:4px">{{ partner.inquiry_count }}</span></div>
      </div>
    </div>

    <section id="partner-info">
      <div class="h6 main">파트너 소개</div>
      <div class="infos">
        <div class="info-item" v-if="partner.business.business_type_kor">
          <img src="/static/icon/u_user.svg" class="svg-sub5">
          <div class="body4 sub2">파트너 구분</div>
          <div class="subtitle7 main">{{ partner.business.business_type_kor }}</div>
        </div>
        <div class="info-item" v-if="partner.business.est_date">
          <img src="/static/icon/u_building.svg" class="svg-sub5">
          <div class="body4 sub2">설립일</div>
          <div class="subtitle7 main">{{ datesFormat(partner.business.est_date, 'date_3') }}</div>
        </div>
        <div class="info-item" v-if="partner.career">
          <img src="/static/icon/u_bag.svg" class="svg-sub5">
          <div class="body4 sub2">경력</div>
          <div class="subtitle7 main">{{ partner.career }}</div>
        </div>
        <div class="info-item" v-if="partner.operating_start && partner.operating_end">
          <img src="/static/icon/u_clock.svg" class="svg-sub5">
          <div class="body4 sub2">연락가능 시간</div>
          <div class="subtitle7 main">{{ partner.operating_start.replace('시', ':00') }} ~ {{ partner.operating_end.replace('시', ':00') }}</div>
        </div>
        <div class="info-item">
          <img src="/static/icon/u_user.svg" class="svg-sub5">
          <div class="body4 sub2">평균 응답 시간</div>
          <div class="subtitle7 main">{{ datesFormat(`${dayjs().format('YYYY-MM-DD')} ${partner.response_time}`, 'duration_1') }}</div>
        </div>
      </div>
      <div v-if="partner.info" class="info-partner" v-html="partner.info"></div>
      <div class="body4 sub3" v-if="partner.updated_by_admin" style="margin-top: 24px">*정책에 따라 일부 내용이 수정되었습니다.</div>
    </section>
    <section id="partner-template">
      <div class="h6 main">템플릿</div>
      <div class="body4 main">총 {{ products.total_count }}개</div>
      <div class="product-list">
        <card-product-temp v-for="product in products.list" :key="product.id" :card="product"></card-product-temp>
      </div>
      <lp-paginate :filtered="products" @getData="getProducts"></lp-paginate>
    </section>
    <section id="partner-review">
      <product-review :productRate="partner.rate" ></product-review>
    </section>
    <section id="partner-inquiry">
      <product-inquiry></product-inquiry>
    </section>

   </div>
</template>
<script>
import StarRating from 'vue-star-rating'
import ProductReview from '@/components/page/ProductReview.vue'
import ProductInquiry from '@/components/page/ProductInquiry.vue'
import CardProductTemp from '@/components/component/CardProductTemp.vue'
import LpPaginate from '@/components/component/LpPaginate.vue'
export default {
   name: 'PartnerDetail',
   components: {
     StarRating,
     ProductReview,
     ProductInquiry,
     CardProductTemp,
     LpPaginate
   },
   mixins: [],
   props: {
     
   },
   data() {
     return {
       partner: null,
       selectedTab: 0,
       products: {
        cur_page: 1,
        page_num: 1,
        page_length: 8,
        total_count: 1,
        total_page: 1,
        list: []
       }
     }
   },
   computed: {
     
   },
   watch: {
     
   },
   created() {
     this.getData();
     this.getProducts();
   },
   methods: {
     getData() {
      this.$axios.get(`public/partner/${this.$route.query.id}`).then(res => {
        this.partner = res.data;
      });
     },
     scrollToSection(sectionId, tabIndex) {
      this.selectedTab = tabIndex;
      const section = document.getElementById(sectionId);
      if (section) {
        const offset = 110; // 스티키 헤더 높이
        const sectionTop = section.getBoundingClientRect().top + window.pageYOffset - offset;
        window.scrollTo({ top: sectionTop, behavior: 'smooth' });
      }
    },
    getProducts() {
      let url = `user/${this.user.user_id}/mapping/product?fields=id,name,img,partner.nickname,price,rate,reviews_count,techs,tags,preview_url,is_liked`;
      url += `&page_num=${this.products.cur_page}&page_length=${this.products.page_length}&category=69&partner_id=${this.$route.query.id}`;
      this.$axios.get(url).then(res => {
        this.products.list = res.data.data;
        this.products.total_count = res.data.count;
        this.products.total_page = res.data.total_page;
      });
    }
   }
};
</script>
<style lang='stylus' scoped>
@import '~assets/css/lp_main'

.partner-detail
  padding-top 64px

.partner-info-wrapper
  padding 32px
  background-color $gray3
  display flex
  justify-content center
  .partner-info
    width 1152px
    background-color #fff
    border-radius 12px
    padding 40px
    display flex
    align-items center
    justify-content space-between

.button-update
  width fit-content
  padding 0 24px


.sticky-header
  position sticky
  position -webkit-sticky
  top 64px
  background-color #fff
  z-index 100
  display flex
  justify-content center
  .sticky-items
    width 1152px
    display flex
    gap 20px
    border-bottom 1px solid $gray
    .sticky-item
      padding 12px 0
      border-bottom 1px solid $gray4
      display flex
      align-items center
      justify-content center
      color $sub3
      border-bottom 2px solid transparent
      &.selected
        border-bottom 2px solid $main
        color $main

section
    width 1152px
    margin 0 auto
    padding 48px 0

.infos
  margin-top 24px
  .info-item
    display flex
    align-items center
    padding 8px 0
    gap 8px
    img
      width 20px
      height 20px

.info-partner
  white-space pre-wrap
  margin-top 24px

#partner-template
  display flex
  flex-direction column
  gap 24px
  .product-list
    display grid
    grid-template-columns repeat(4, 1fr)
    gap 48px 24px
    padding-bottom 40px

@media (max-width: 600px)
  .partner-detail
    padding-top 60px
  .partner-info-wrapper
    padding 16px
    .partner-info
      width 100%
      padding 16px
      flex-direction column
      align-items flex-start
      gap 40px
      a
      .button-update
        width 100%
        height 52px

  .sticky-header
    padding 0 16px
    top 59.5px
  section
    width 100%
    padding 24px 16px
</style>